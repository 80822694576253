.Offline {
  background-color: #FFAAAA;
}

.Available {
  background-color: #88AAFF;
}

.OnaVideoCall {
  background-color: #33FF33;
}

.alive {
  background-color: #FFFFFF;
}

.unresponsive {
  background-color: #CCCCCC;
}